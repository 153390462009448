const PATHS = {
  home: '/',
  login: '/login',
  register: '/register',
  dashboard: '/dashboard',
  weddings: '/weddings',
  settings: '/settings/:id',
  task: '/task',
  taskList: '/tasks/:id',
  guest: '/guest',
  guestList: '/guest/:id',
  addGuest: '/guest-add/:id',
  editGuest: '/guest-edit/:id',
  wedding: '/wedding/:id',
  addWedding: '/wedding-add',
  editWedding: '/wedding-edit/:id',
  addTask: '/task-add/:id',
  editTask: '/task-edit/:weddingId/:id',
  wishlist: '/wishlist/:id?',
  tablePlacement: '/table-placement/:id?',
  Table: '/table/:id?',
  schedules: '/schedules/:id?',
  addActivity: '/add-activity/:id?',

}
export default PATHS
