import { ENDPOINTS } from 'consts/api'

import API from '../../utils/http/apiCalls'

export const ADD_WEDDINGS = 'ADD_WEDDINGS'
export const ADD_CURRENT_WEDDING = 'ADD_CURRENT_WEDDING'
export const ADD_TASKS = 'ADD_TASKS'
export const ADD_GUESTS = 'ADD_GUESTS'
export const ADD_IMAGES = 'ADD_IMAGES'

export const fetchWeddings = () => (dispatch) => {
  API.get({ url: ENDPOINTS.allWeddings })
    .then((res) => {
      dispatch({
        type: ADD_WEDDINGS,
        payload: res.weddings,
      })
    })
    .catch((error) => console.log(error))
}

export const fetchCurrentWedding = (weddingId) => (dispatch) => {
  API.get({ url: `${ENDPOINTS.addWedding}/${weddingId}` })
    .then((res) => {
      dispatch({
        type: ADD_CURRENT_WEDDING,
        payload: res,
      })
    })
    .catch((error) => console.log(error))
}

export const fetchTasks = (weddingId) => (dispatch) => {
  API.get({ url: `${ENDPOINTS.weddingTasks}/${weddingId}` })
    .then((res) => {
      dispatch({
        type: ADD_TASKS,
        payload: res,
      })
    })
    .catch((error) => console.log(error))
}

export const fetchGuests = (weddingId) => (dispatch) => {
  API.get({ url: `${ENDPOINTS.weddingGuests}/${weddingId}` })
    .then((res) => {
      dispatch({
        type: ADD_GUESTS,
        payload: res,
      })
    })
    .catch((error) => console.log(error))
}

export const fetchImages = (weddingId) => (dispatch) => {
  API.get({ url: `${ENDPOINTS.weddingImage}/${weddingId}` })
    .then((res) => {
      dispatch({
        type: ADD_IMAGES,
        payload: res,
      })
    })
    .catch((error) => console.log(error))
}

export const initCurrentWedding = (weddingId) => (dispatch) => {
  dispatch(fetchCurrentWedding(weddingId))
  dispatch(fetchGuests(weddingId))
  dispatch(fetchTasks(weddingId))
  dispatch(fetchImages(weddingId))
}
