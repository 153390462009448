import React, { Suspense } from 'react'

import { Route, Switch } from 'react-router-dom'

import ROUTES from './routes'

const RoutesSwitch = () => {
  const routes = ROUTES.map((route) => <Route key={route.path} exact path={route.path} component={route.component} />)
  return (
    <Suspense fallback={<div>&nbsp;</div>}>
      <Switch>{routes}</Switch>
    </Suspense>
  )
}
export default RoutesSwitch
