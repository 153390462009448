import React from 'react'

import Auth from 'views/HOC/Auth'

import PATHS from './paths'

const Login = React.lazy(() => import('views/Auth/Login/Login'))
const Register = React.lazy(() => import('views/Auth/Register/Register'))
const Weddings = React.lazy(() => import('views/Weddings/Weddings'))
const AddWedding = React.lazy(() => import('views/Weddings/AddWedding/AddWedding'))
const EditWedding = React.lazy(() => import('views/Weddings/EditWedding/EditWedding'))
const WeddingInfo = React.lazy(() => import('views/Weddings/WeddingInfo/WeddingInfo'))
const AddTask = React.lazy(() => import('views/Task/AddTask/AddTask'))
const EditTask = React.lazy(() => import('views/Task/EditTask/EditTask'))
const AddGuest = React.lazy(() => import('views/Guest/AddGuest/AddGuest'))
const EditGuest = React.lazy(() => import('views/Guest/EditGuest/EditGuest'))
const TaskList = React.lazy(() => import('views/TaskList/TaskList'))
const GuestsList = React.lazy(() => import('views/GuestList/GuestsList'))
const Settings = React.lazy(() => import('views/Settings/Settings'))
const TablePlacement = React.lazy(() => import('views/Table/TablePlacement'))
const Table = React.lazy(() => import('views/Table/Table'))
const Schedule = React.lazy(() => import('views/Schedule/Schedule'))
const AddActivity = React.lazy(() => import('views/Schedule/AddActivity'))

const ROUTES = [
  { path: PATHS.home, component: Auth(Login) },
  { path: PATHS.login, component: Auth(Login) },
  { path: PATHS.register, component: Register },
  { path: PATHS.dashboard, component: Auth(Weddings) },
  { path: PATHS.weddings, component: Auth(Weddings) },
  { path: PATHS.addWedding, component: Auth(AddWedding) },
  { path: PATHS.editWedding, component: Auth(EditWedding) },
  { path: PATHS.wedding, component: Auth(WeddingInfo) },
  { path: PATHS.settings, component: Auth(Settings) },
  { path: PATHS.taskList, component: Auth(TaskList) },
  { path: PATHS.addTask, component: Auth(AddTask) },
  { path: PATHS.editTask, component: Auth(EditTask) },
  { path: PATHS.guestList, component: Auth(GuestsList) },
  { path: PATHS.addGuest, component: Auth(AddGuest) },
  { path: PATHS.editGuest, component: Auth(EditGuest) },
  { path: PATHS.tablePlacement, component: Auth(TablePlacement) },
  { path: PATHS.table, component: Auth(Table) },
  { path: PATHS.schedules, component: Auth(Schedule) },
  { path: PATHS.addActivity, component: Auth(AddActivity) },
]
export default ROUTES
