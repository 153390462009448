import {
  ADD_CURRENT_WEDDING, ADD_GUESTS, ADD_IMAGES, ADD_TASKS, ADD_WEDDINGS,
} from '../actions/weddingActions'

const INITIAL_STATE = {
  weddings: [],
  currentWedding: {
    tasks: [],
    guests: [],
    images: [],
  },
}

export const weddingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_WEDDINGS:
      return { ...state, weddings: action.payload }
    case ADD_CURRENT_WEDDING:
      return { ...state, currentWedding: { ...state.currentWedding, ...action.payload } }
    case ADD_TASKS:
      return { ...state, currentWedding: { ...state.currentWedding, ...action.payload } }
    case ADD_GUESTS:
      return { ...state, currentWedding: { ...state.currentWedding, ...action.payload } }
    case ADD_IMAGES:
      return { ...state, currentWedding: { ...state.currentWedding, ...action.payload } }
    default:
      return state
  }
}
