import React, { Component, Suspense } from 'react'

import RoutesSwitch from './routes/RoutesSwitch'

class App extends Component {
  render() {
    return (
      <Suspense fallback={<div />}>
        <RoutesSwitch />
      </Suspense>
    )
  }
}
export default App
