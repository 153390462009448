import { API_URL, ENDPOINTS } from 'consts/api'

import { getItemFromLocaleStorage, saveItemToLocaleStorage } from '../localeStorage'
import API from './apiCalls'

const token = getItemFromLocaleStorage('token')

export const HTTP = ({
  url, method, body = {}, authentication,
}) => {
  const requestParams = prepareRequestParams(method, body, authentication)

  return fetch(
    `${API_URL}${url}`,
    requestParams
  )
    .then((res) => {
      if (res.status === 401) {
        return refreshToken(requestParams).then((token) => HTTP({
          url, method, body, authentication: token,
        }))
      }
      if (res.status === 200) return res.json()
    })
    .catch((err) => {
      console.log(err)
    })
}

const refreshToken = () => API.post({ url: ENDPOINTS.refreshToken, params: { token, id: getItemFromLocaleStorage('userId') } })
  .then((res) => {
    saveItemToLocaleStorage('token', res.token)
    return res.token
  })

const prepareRequestParams = (method, body, authentication) => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    method,
  }

  if (body instanceof FormData) {
    options.body = body
    options.headers = {}
  }

  if (token) options.headers.Authorization = `Bearer ${authentication}`

  if (method === 'GET') delete options.body

  return options
}
