import { ENDPOINTS } from 'consts/api'

import API from '../../utils/http/apiCalls'

export const ADD_USER = 'ADD_USER'

export const getCurrentUser = () => (dispatch) => {
  API.get({ url: ENDPOINTS.userMe })
    .then((res) => {
      dispatch({ type: ADD_USER, payload: res })
    })
    .catch((error) => console.log(error))
}
