import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'

import { userReducer } from './userReducer'
import { weddingsReducer } from './weddingReducer'

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  weddings: weddingsReducer,
  user: userReducer,
})
export default createRootReducer
