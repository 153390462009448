import { getItemFromLocaleStorage } from '../localeStorage'
import { HTTP } from './fetch'

const token = getItemFromLocaleStorage('token')

const API = {
  post: ({ url, params }) => HTTP({
    url,
    method: 'POST',
    body: params,
    authentication: token,
  }),
  get: ({ url }) => HTTP({
    url,
    method: 'GET',
    body: null,
    authentication: token,
  }),
  put: ({ url, params }) => HTTP({
    url,
    method: 'PUT',
    body: params,
    authentication: token,
  }),
  del: ({ url, params }) => HTTP({
    url,
    method: 'DELETE',
    body: params,
    authentication: token,
  }),
}
export default API
