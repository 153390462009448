import React, { Component } from 'react'

import { connect } from 'react-redux'

import { getCurrentUser } from 'store/actions/userActions'
import { history } from 'store/store'
import { getItemFromLocaleStorage } from 'utils/localeStorage'

import PATHS from '../../routes/paths'

export default function (ComposedComponent) {
  class Auth extends Component {
    constructor(props) {
      super(props)
      this.checkAuth()
    }

    checkAuth() {
      const token = getItemFromLocaleStorage('token')

      if (!token) history.push('/')
      if (token && history.location.pathname === '/') history.push(PATHS.dashboard)
    }

    render() {
      return <ComposedComponent {...this.props} />
    }
  }

  const mapDispatchToProps = {
    getCurrentUser,
  }

  return connect(null, mapDispatchToProps)(Auth)
}
