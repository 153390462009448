import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

import createRootReducer from './reducers/combineReducers'

const middleware = [thunk]
const enhancers = []

if (window.__REDUX_DEVTOOLS_EXTENSION__) {
  enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__())
}

export const history = createBrowserHistory()

export const store = createStore(
  createRootReducer(history),
  compose(
    applyMiddleware(
      ...middleware,
      routerMiddleware(history)
    ),
    ...enhancers
  )
)
