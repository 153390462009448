export const ENDPOINTS = {
  facebookLogin: 'user/facebook',
  googleLogin: 'user/google',
  userToken: 'user/token',
  userRegister: 'user/register',
  refreshToken: 'user/refreshToken',
  userMe: 'user/me',
  addWedding: 'wedding',
  allWeddings: 'wedding/all/weddings',
  addTask: 'task',
  weddingTasks: 'task/wedding',
  addGuest: 'guest',
  weddingGuests: 'guest/wedding',
  guestComment: 'guest/comment',
  comment: 'comment',
  changePermissions: 'wedding/member/changePermissions',
  leaveWedding: 'wedding/leave',
  invitation: 'invitation',
  resendInvitation: 'invitation/resend',
  weddingImage: 'wedding-image',
  weddingImageComment: 'wedding-image/comment',
  giftList: 'gift/wishlist/wedding',
  giftContribution: 'gift/giftContributor',
}
export const API_URL = 'http://api2.dev.wedme.com/api/'
